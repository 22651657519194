import { BrowserStorage, BackendStorage } from '@/storage'

export default async function createProject ({ state, commit, getters }) {
  const storageItem = {
    ...state,
    symbols: getters.allItems('symbols')
  }

  const projectId = await BackendStorage.createProject(storageItem)

  commit('UPDATE_PROJECT', {
    id: projectId,
    updatedAt: new Date()
  })
  commit('SET_HAS_UNSAVED_CHANGES', false)

  // remove item from browser storage
  BrowserStorage.delete(process.env.VUE_APP_LOCAL_PROJECT_ID)

  console.debug('Created new project', { projectId })

  return projectId
}
