import detectPointer from 'detect-pointer'
import { nanoid } from 'nanoid'
import padStart from 'lodash/padStart'
import FileSaver from 'file-saver'

export function createId (prefix) {
  const id = nanoid(14)
  return typeof prefix === 'string' ? `${prefix}-${id}` : id
}

// src: https://gomakethings.com/dynamically-changing-the-text-color-based-on-background-color-contrast-with-vanilla-js/
// 0: black
// 1: white
export function getContrastColor (hexcolor) {
  hexcolor = hexcolor.slice(1)
  var r = parseInt(hexcolor.substr(0, 2), 16)
  var g = parseInt(hexcolor.substr(2, 2), 16)
  var b = parseInt(hexcolor.substr(4, 2), 16)
  var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000
  return (yiq >= 128) ? 0 : 1
}

export function getContrastColorString (hexcolor) {
  return getContrastColor(hexcolor) === 0
    ? 'black'
    : 'white'
}

export function downloadFromString (text, fileName, type = 'text/plain;charset=utf-8') {
  const blob = new Blob([text], { type })
  FileSaver(blob, fileName, type)
}

export function insert (array, item, index) {
  const copy = [].concat(array)
  if (index == null || typeof index === 'undefined') {
    index = array.length
  }
  copy.splice(index, 0, item)
  return copy
}

export function createOutputFilename () {
  const pad = (str) => padStart(str, 2, '0')
  const now = new Date()
  return `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())}-${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`
}

export async function fetchRequest (url, method, data) {
  if (!url) {
    throw new Error('No URL provided')
  }

  if (!['GET', 'POST', 'PUT'].includes(method)) {
    throw new Error('No valid method provided')
  }

  const options = {
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow' // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  }

  if (data) {
    options.body = JSON.stringify(data) // body data type must match "Content-Type" header
  }

  // Default options are marked with *
  const response = await window.fetch(url, options)

  return response
}

export const copyToClipboard = (value) => {
  const hiddenInput = document.createElement('textarea')
  hiddenInput.value = value
  hiddenInput.setAttribute('readonly', '')
  hiddenInput.style.position = 'absolute'
  hiddenInput.style.left = '-9999px'
  document.body.appendChild(hiddenInput)
  hiddenInput.select()
  document.execCommand('copy')
  document.body.removeChild(hiddenInput)
}

export const hasPointer = detectPointer.fine
