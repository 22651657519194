import last from 'lodash/last'

const mutations = {

  UPDATE_LANG (state, val) {
    state.lang = val
  },

  SET_ACTIVE_PANEL_ID (state, val) {
    state.activePanelId = val
    if (val && last(state.activePanelHistory) !== val) {
      state.activePanelHistory.push(val)
    }
  },

  SET_ACTIVE_PANEL_HISTORY (state, val) {
    state.activePanelHistory = val
  },

  SET_IS_DRAGGING (state, val) {
    state.isDragging = val
  },

  SET_PANEL_GROUPS_ORDER (state, val) {
    state.panelGroupsOrder = val
  },

  SET_HAS_UNSAVED_CHANGES (state, value) {
    state.hasUnsavedChanges = value
  }

}

export default mutations
