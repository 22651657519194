<template lang="pug">
  #app
    Header(v-if='showHeader')
    router-view
</template>

<script>
import Header from '@/components/Header'
export default {
  name: 'App',

  metaInfo () {
    return {
      titleTemplate: (titleChunk) => {
        const appTitle = process.env.VUE_APP_TITLE
        return titleChunk ? `${titleChunk} | ${appTitle}` : appTitle
      }
    }
  },

  components: {
    Header
  },

  computed: {
    showHeader () {
      return this.$route.meta.showHeader === true
    }
  }

}
</script>

<style lang="scss">
@import '@/assets/scss/global.scss';
</style>
