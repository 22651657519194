import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isToday from 'dayjs/plugin/isToday'

dayjs.extend(localizedFormat)
dayjs.extend(isToday)

export default {
  install (Vue) {
    Vue.prototype.$dayjs = dayjs
  }
}
