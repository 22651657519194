<template lang="pug">
  .z-20.absolute.flex.justify-end.bg-white(
    class='bottom-2 right-3'
  )
    Button(
      class='opacity-60 hover:opacity-100'
      v-for='item in items'
      :key='item.id'
      :label='item.label'
      :show-label='item.showLabel'
      theme='workbench'
      size='small'
      :icon='item.icon'
      v-tippy='item.tippy'
      @click='item.onClick'
    )

</template>

<script>
export default {
  name: 'PanelMenu',

  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>
