const getters = {
  itemById: (state) => (where, id) => state[where][id],

  allItems: (state) => (where) => state[where] ? Object.values(state[where]) : [],

  findItemsByKeyValue: (state) =>
    (where, key, value) =>
      Object.values(state[where])
        .filter(item => item[key] === value),

  findItemsByKeyValueContains: (state) =>
    (where, key, value) =>
      Object.values(state[where])
        .filter(item => Array.isArray(item[key]) && item[key].includes(value)),

  resolvePanelGroup: (state, getters) => panelId => getters.findItemsByKeyValueContains('panelGroups', 'panelIds', panelId)[0],

  resolveSymbolPath: (state, getters) => symbolId => {
    const result = { panelGroupId: null, panelId: null, tabId: null, editorId: null, symbolId }
    const allItems = getters.allItems

    result.editorId = allItems('editors')
      .find(e => e.symbolId === symbolId)?.id || null
    if (result.editorId) {
      result.tabId = allItems('tabs')
        .find(t => t.editorId === result.editorId)?.id
      if (result.tabId) {
        result.panelId = allItems('panels')
          .find(p => p.tabIds.includes(result.tabId))?.id
        if (result.panelId) {
          result.panelGroupId = allItems('panelGroups')
            .find(g => g.panelIds.includes(result.panelId))?.id
        }
      }
    }

    return result
  }

}

export default getters
