<template lang="pug">
  QuickPanel(
    @close='closeQuickPanel'
    v-slot
  )
    TextInput.symbol-picker(
      ref='nameInput'
      v-model='symbol'
      theme='quickpanel'
      :autocomplete='{ items: suggestions, showOnStart: true, resultsStartAt: suggestions.length > 1 && suggestions[0].createNew ? 1 : 0 }'
      placeholder='Enter a Symbol name'
      @input='updateValue'
      @selectItem='selectItem'
    )
</template>

<script>
import Fuse from 'fuse.js'
import { mapGetters, mapState } from 'vuex'
import { createId, insert } from '@/util'

export default {
  name: 'QuickPanelSymbolPicker',

  props: {
    panelId: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      symbolName: '',
      symbolId: null,
      hasError: false,
      hint: 'No whitespace allowed. Enter at least 1 character.'
    }
  },

  mounted () {
    this.$store.commit('SET_ACTIVE_PANEL_ID', null)
    this.$refs.nameInput.focus()
  },

  computed: {
    ...mapGetters(['allItems']),
    ...mapState({
      activePanelId: state => state.workbench.activePanelId
    }),

    symbol: {
      get () {
        return this.symbolName
      },
      set (label) {
        this.symbolName = label
      }
    },

    suggestions () {
      if (this.hasError) {
        return [{
          label: this.hint, isError: true
        }]
      }

      const symbols = this.allItems('symbols')
      const transformSymbol = (symbol, matches) => {
        let nameVal = symbol.name

        if (matches) {
          for (let i = 0, l = matches.length; i < l; i++) {
            const match = matches[i]
            nameVal = generateHighlightedText(match.value, match.indices)
          }
        }

        return {
          label: `<span class="suggestion-symbol" style="--color:${symbol.color}">${nameVal}</span>`,
          value: symbol.id
        }
      }
      const generateHighlightedText = (inputText, regions) => {
        // source: https://gist.github.com/evenfrost/1ba123656ded32fb7a0cd4651efd4db0
        let content = ''
        let nextUnhighlightedRegionStartingIndex = 0

        regions.forEach(region => {
          const lastRegionNextIndex = region[1] + 1

          content += [
            inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]),
            '<strong>',
            inputText.substring(region[0], lastRegionNextIndex),
            '</strong>'
          ].join('')

          nextUnhighlightedRegionStartingIndex = lastRegionNextIndex
        })

        content += inputText.substring(nextUnhighlightedRegionStartingIndex)

        return content
      }

      if (!this.symbolName.length) return symbols.map(s => transformSymbol(s))

      const searchResult = new Fuse(symbols, {
        threshold: 0.3,
        includeMatches: true,
        keys: ['name']
      }).search(this.symbolName)
        .map(i => {
          return transformSymbol(i.item, i.matches)
        })
      return insert(
        searchResult,
        {
          label: `✏️ Create symbol "<strong>${this.symbolName}</strong>"`,
          createNew: true
        },
        0
      )
    }

  },

  methods: {

    updateValue (label) {
      const maxLength = 40

      if (
        label.length > maxLength ||
        /\s/.test(label)
      ) {
        this.hasError = true
        return
      }

      this.hasError = false
    },

    selectItem ({ label, value, isError, createNew }) {
      if (isError === true) return
      let symbolId = value

      if (createNew === true) {
        // create symbol
        symbolId = createId('s')
        this.$store.commit('INSERT', {
          where: 'symbols',
          data: {
            id: symbolId,
            name: this.symbolName,
            color: this.$randomColor()
          }
        })
      }

      this.closeQuickPanel() // order important; resets activePanelId

      // open symbol in tab
      this.$store.dispatch('openSymbolInTab', {
        symbolId, targetPanelId: this.panelId || this.activePanelId
      })
    },

    closeQuickPanel () {
      this.$quickPanel.close()
      this.$store.dispatch('focusLastActivePanel')
    }
  }
}
</script>

<style lang="scss">
.suggestion-symbol {
  @apply pl-1.5 flex;
  border-left: 2px solid var(--color);
}

.symbol-picker {
  .suggestions {
    @apply scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent;
  }
    .suggestion-item {
      @apply mb-0.5 last:mb-0 text-sm text-white;
      @apply hover:bg-gray-900;
      &--selected {
        @apply bg-gray-800;
      }
    }
}

</style>
