import { createId } from '@/util'
import initialStory from './initial-story.json'
import blankStory from './blank-story.json'
import migrateSymbols from './migrateSymbols'

export default async function createWorkspace (
  { commit, rootState },
  {
    project = null,
    symbols = [],
    exampleStory = true,
    clear = true
  } = {}
) {
  const startPanelId = createId('panel')
  const startTabId = createId('tab')
  const startEditorId = createId('editor')
  const startSymbolId = project && project.startSymbolId
    ? project.startSymbolId
    : process.env.VUE_APP_ORIGIN_SYMBOL_ID
  const startOutputPanelId = createId('panel')
  const startOutputTabId = createId('tab')

  if (!symbols.length) {
    if (exampleStory === true) {
      symbols = initialStory
    } else {
      symbols = blankStory
    }
  } else {
    if (
      !project ||
      typeof project.schemaVersion !== 'string' ||
      project.schemaVersion < '1.0'
    ) {
      symbols = migrateSymbols(symbols)
    }
  }

  // clear workspace
  if (clear) {
    commit('RESET_STATE')
  }

  // insert Project
  if (project) {
    commit('UPDATE_PROJECT', project)
  }

  // insert Symbol
  commit('INSERT', {
    where: 'symbols',
    data: symbols
  })

  // insert Editor
  commit('INSERT', {
    where: 'editors',
    data: {
      id: startEditorId,
      symbolId: startSymbolId
    }
  })

  // insert Tabs
  commit('INSERT', {
    where: 'tabs',
    data: [
      {
        id: startTabId,
        label: symbols.find(s => s.id === startSymbolId).name,
        editorId: startEditorId
      },
      {
        id: startOutputTabId,
        label: 'Result',
        canClose: false,
        componentName: 'PanelOutput'
      }
    ]
  })

  // insert Panels
  commit('INSERT', {
    where: 'panels',
    data: [
      // top Panel
      {
        id: startPanelId,
        activeTabId: startTabId,
        tabIds: [startTabId]
      },
      // bottom Panel
      {
        id: startOutputPanelId,
        activeTabId: startOutputTabId,
        tabIds: [startOutputTabId]
      }
    ]
  })

  // insert PanelGroups
  const panelGroupId1 = createId('pg')
  const panelGroupId2 = createId('pg')
  commit('INSERT', {
    where: 'panelGroups',
    data: [
      { id: panelGroupId1, panelIds: [startPanelId] },
      { id: panelGroupId2, panelIds: [startOutputPanelId] }
    ]
  })

  commit('SET_ACTIVE_PANEL_ID', startPanelId)
  commit('SET_PANEL_GROUPS_ORDER', [panelGroupId1, panelGroupId2])

  console.debug('New Workspace created:', rootState)
}
