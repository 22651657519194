<template lang="pug">
  Output.h-full(
    :errors='$generateMessages.errors'
    :text='$generatedText'
    :menuItems='panelMenuItems'
  )
</template>

<script>

import { copyToClipboard } from '@/util'
import Output from '@/components/output/Output'

export default {
  name: 'PanelOutput',

  components: { Output },

  data () {
    return {
      panelMenuItems: [
        {
          label: 'Generate',
          showLabel: false,
          icon: 'ReloadIcon',
          tippy: {
            placement: 'top',
            content: 'Generate'
          },
          onClick: () => {
            this.$generate()
          }
        },
        {
          label: 'Copy text',
          showLabel: false,
          icon: 'ContentCopyIcon',
          tippy: {
            placement: 'top',
            content: 'Copy text'
          },
          onClick: this.copyOutput
        }
      ]
    }
  },

  methods: {
    copyOutput (e) {
      copyToClipboard(this.$generatedText)

      // show feedback
      const btn = e.target
      btn._tippy.hide()
      setTimeout(() => {
        btn._tippy.destroy()
        this.$tippy(btn, {
          showOnCreate: true,
          placement: 'top',
          content: 'Copied'
        })
        setTimeout(() => {
          btn._tippy.setContent('Copy text')
        }, 5000)
      }, 200)
    }
  }

}
</script>
