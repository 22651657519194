import cloneDeep from 'lodash/cloneDeep'
import uniq from 'lodash/uniq'
import History from './History'
import buildState from './buildState'
import flatten from './flatten'
import { noRulesCode } from './'

const createStory = (
  startSymbolId,
  symbolsObj,
  {
    separator = '\n',
    lang = 'en'
  } = {}) => ({
  symbols: cloneDeep(symbolsObj), // to prevent vuex state mutation
  history: new History(symbolsObj),
  settings: { separator, lang },
  text: '',
  errors: [],
  savedRules: {},

  get messages () {
    // create a new array to prevent 2-way-binding
    return { errors: [...uniq(this.errors)] }
  },

  generate ({
    times = 50,
    minLength
  } = {}) {
    let i = 0
    const hasMinLen = typeof minLength !== 'undefined'
    const { id, name } = this.symbols[startSymbolId]

    if (!id) {
      this.errors.push(`Start symbol with id ${startSymbolId} not found in symbols`)
      return
    }

    while (true) {
      const state = buildState(
        this,
        { id, name, variant: 1, mods: {} },
        undefined,
        this.savedRules
      )
      // console.debug('all saved selections', savedRules)

      if (state === noRulesCode) {
        console.debug(`Root state is empty at position ${i + 1}`)
        break
      }

      // flatten state
      this.text += flatten(state, this.settings.lang)

      // add separator
      this.text += this.settings.separator

      // exit condition
      i++
      if (hasMinLen) {
        if (this.text.length >= minLength) {
          break
        }
      } else if (i >= times) {
        break
      }
    }
  }
})

export default createStory
