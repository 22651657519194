<template lang="pug">
  .relative.h-full.bg-white
    PanelMenu(
      v-if='menuItems.length > 0'
      :items='menuItems'
    )

    Scroll(ref='scroller')
      .p-5
        .border.border-red-500.ring.ring-red-500.ring-opacity-20.p-3.mb-4(v-if='errors.length')
          //- messages
          .flex.items-center(
            v-for='err in errors'
            class='text-red-500'
          )
            AlertOctagramIcon(:size='24' class='mr-0.5') &nbsp;
            span(v-html='err')

        //- output text
        .whitespace-pre-wrap.break-words
          | {{ text }}
          .text-gray-700(
            v-if='!text.length && !errors.length'
          ) Nothing to generate 😢

</template>

<script>

export default {
  name: 'Output',

  props: {

    errors: {
      type: Array,
      default: () => []
    },

    text: {
      type: String,
      required: false
    },

    menuItems: {
      type: Array,
      default: () => []
    }
  },

  watch: {
    // scroll top, when text updates
    changedOutput (newVal, oldVal) {
      this.$refs.scroller.setScrollPosition({ scrollTop: 0 })
    }
  },

  computed: {
    changedOutput () {
      return this.text.length + this.errors.length
    }

  }

}
</script>
