export const initialState = () => ({
  id: process.env.VUE_APP_LOCAL_PROJECT_ID,
  title: 'Untitled',
  author: '',
  isPublic: false,
  updatedAt: new Date(),
  schemaVersion: '1.0',
  startSymbolId: process.env.VUE_APP_ORIGIN_SYMBOL_ID,
  separator: '\n'
})

export default initialState
