<template lang="pug">
  header.bg-gray-50
    .flex.items-center.justify-center(
      :class='headerSize === "large" ? "h-48 lg:h-72" : "h-40 lg:h-60"'
    )
      router-link(to='home')
        img(
          src='/img/logo.svg'
          alt=''
          :class='headerSize === "large" ? "w-40 lg:w-44" : "w-36 lg:w-44"'
        )
    nav.border-b-2.border-black(
      v-if='showMenu'
    )
      .flex.justify-center.max-w-prose.mx-auto.w-full(class='px-4 md:px-0')
        .flex(
          v-for='menuItem in menu'
        )
          router-link.text-center.font-bold.px-4.py-2.transition-all(
            :class='`hover:underline ${menuItem.class}`'
            active-class='bg-black text-white'
            :to='menuItem.routeName'
          ) {{ menuItem.label }}
</template>

<script>
import { hasPointer } from '@/util'

export default {
  name: 'Header',

  props: {
    showMenu: {
      type: Boolean,
      default: true
    }
  },

  mounted () {
    if (hasPointer) {
      this.menu.unshift({
        label: 'Tutorial',
        routeName: 'tutorial'
      })
      this.menu.push({
        label: 'Launch Plauder',
        routeName: 'workbench',
        class: 'text-blue-600'
      })
    }
  },

  data () {
    return {
      menu: [
        {
          label: 'Resources',
          routeName: 'resources'
        },
        {
          label: 'About',
          routeName: 'about'
        }
      ]
    }
  },

  computed: {
    headerSize () {
      return this.$route.name === 'home' ? 'large' : 'small'
    }
  }
}
</script>
