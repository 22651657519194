import { createId, insert } from '@/util'

export default async function openComponentInTab (
  { commit, dispatch, state, getters },
  { tabLabel, tabIcon = null, componentName }
) {
  // check if component is already opened
  const queriedTabs = getters.findItemsByKeyValue('tabs', 'componentName', componentName)
  if (queriedTabs.length) {
    const openedTabId = queriedTabs[0].id
    const panel = getters.findItemsByKeyValueContains('panels', 'tabIds', openedTabId)[0]
    // set active panel and active tab
    if (state.activePanelId !== panel.id) {
      commit('SET_ACTIVE_PANEL_ID', panel.id)
    }
    if (panel.activeTabId !== openedTabId) {
      commit('UPDATE', {
        where: 'panels',
        data: {
          id: panel.id,
          activeTabId: openedTabId
        }
      })
    }
    return
  }

  const activePanelId = state.activePanelId
  const activePanel = getters.itemById('panels', activePanelId)
  const activeTabId = activePanel.activeTabId
  const tabIds = activePanel.tabIds
  const indexOfActiveTabId = tabIds.indexOf(activeTabId)

  // insert new tab behind active tab
  const newTabId = createId('tab')

  // create tab
  commit('INSERT', {
    where: 'tabs',
    data: {
      id: newTabId,
      label: tabLabel,
      icon: tabIcon,
      componentName
    }
  })

  // update panel
  commit('UPDATE', {
    where: 'panels',
    data: {
      id: activePanel.id,
      activeTabId: newTabId,
      tabIds: insert(activePanel.tabIds, newTabId, indexOfActiveTabId)
    }
  })
}
