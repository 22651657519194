<template lang="pug">
  .z-10.relative.h-full
    //- shadow
    .div(
      :class='[ \
        "absolute z-20 w-full h-1 bg-black bg-opacity-5 transition-opacity", \
        (scrollTop > 0 ? "opacity-1" : "opacity-0") \
      ]'
    )
    //- content
    .z-10.scroll-padding.relative.overflow-auto.h-full.scrollbar-thin.scrollbar-thumb-gray-500.scrollbar-track-white(
      ref='container' @scroll='debounceHandleScroll'
    )
      slot
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  name: 'Scroll',

  data () {
    return {
      scrollTop: 0,
      scrollLeft: 0,
      resizeOvserver: null,
      debounceHandleScroll: debounce(this.handleScroll, 10)
    }
  },

  methods: {
    handleScroll () {
      // save scroll position
      if (!this.$refs.container) return
      this.scrollTop = this.$refs.container.scrollTop
      this.scrollLeft = this.$refs.container.scrollLeft
      this.$emit('update', [this.scrollTop, this.scrollLeft])
    },

    setScrollPosition ({ scrollTop, scrollLeft }) {
      if (scrollTop) {
        this.$refs.container.scrollTop = scrollTop
      }
      if (scrollLeft) {
        this.$refs.container.scrollLeft = scrollLeft
      }
    },

    getContentContainer () {
      return this.$refs.container
    }
  }
}
</script>

<style lang="scss">
.scroll-padding {
  scroll-padding: theme('spacing.8');
}
</style>
