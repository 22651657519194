<template lang="pug">
  .flex.flex-col
    button(
      v-for="item in items"
      :class='[ \
        "flex flex-nowrap items-center content-center text-sm leading-snug px-0.5 mb-0.5 last:mb-0", \
        (item.disabled && item.disabled === true ? "text-gray-500 cursor-default" : "text-white cursor-pointer hover:bg-gray-800 focus:outline-none") \
      ]'
      @click='item.onClick'
    )
      component(:is='item.icon' :size='20')
      span.ml-1 {{ item.label }}
</template>

<script>
export default {
  name: 'ContextMenu',

  props: {
    target: {
      type: [String, HTMLElement],
      required: true
    },

    items: {
      type: Array,
      required: true
    },

    offset: {
      type: Number,
      required: false
    },

    placement: {
      type: String,
      default: 'top'
    }
  },

  data () {
    return {
      popup: null
    }
  },

  mounted () {
    this.initPopup()
  },

  beforeDestroy () {
    this.destroyPopup()
  },

  methods: {
    initPopup () {
      const params = {
        showOnCreate: true,
        interactive: true,
        placement: this.placement,
        trigger: 'manual',
        appendTo: () => document.body,
        content: this.$el,
        // onShow: (instance) => {
        //   this.importExportMenuIsVisible = true
        // },
        onHidden: (instance) => {
          this.destroyPopup()
        },
        onDestroy: (instance) => {
          this.$emit('destroyed')
        }
      }

      if (this.offset) {
        params.offset = this.offset
      }

      this.popup = this.$tippy(this.target, params)
    },

    destroyPopup () {
      if (this.popup) {
        if (Array.isArray(this.popup)) {
          this.popup[0].destroy()
        } else {
          this.popup.destroy()
        }
        this.popup = null
      }
    }

  }
}
</script>
