import { fetchRequest } from '@/util'

export const BrowserStorage = {

  get (key) {
    const data = localStorage.getItem(key)
    return data ? JSON.parse(data) : null
  },

  save (key, obj) {
    localStorage.setItem(key, JSON.stringify(obj))
  },

  delete (key) {
    localStorage.removeItem(key)
  }
}

// using native fetch() api: https://blog.logrocket.com/axios-or-fetch-api/
export const BackendStorage = {
  apiUrl: process.env.VUE_APP_API_URL,

  isValidId (id) {
    return /^[a-z0-9-_]{10,}$/i.test(id)
  },

  async getProject (id) {
    const url = this.apiUrl + '/project/' + id
    const response = await fetchRequest(url, 'GET')
    const json = await response.json()

    if (!response.ok) {
      throw new Error(json.message)
    }

    return json
  },

  async createProject (project) {
    const url = this.apiUrl + '/project/new'
    const response = await fetchRequest(url, 'POST', project)

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    // convert body to json
    const json = await response.json()
    if (!json.id || !this.isValidId(json.id)) {
      throw new Error('Property "id" missing or wrong in response')
    }

    return json.id
  },

  async updateProject (id, project) {
    const url = this.apiUrl + '/project/' + id
    const response = await fetchRequest(url, 'PUT', project)

    if (!response.ok) {
      throw new Error(response.statusText)
    }
  }
}

// export default {
//   get workspace () {
//     const data = localStorage.getItem('workspace')
//     if (data) {
//       return JSON.parse(data)
//     }
//     return false
//   },
//   saveItem (key, obj) {
//     localStorage.setItem(key, JSON.stringify(obj))
//     // console.warn('localStorage projects:', this.projects)
//   },
//   saveWorkspace (backup) {
//     this.saveItem('workspace', backup)
//   }

// get projects () {
//   const data = localStorage.getItem('projects')
//   if (data) {
//     return JSON.parse(data)
//   }
//   return []
// },
// saveProjects (projectList) {
//   this.saveItem('projects', projectList)
// },
// insertProject ({
//   id = createId('project'),
//   name = '',
//   symbols = [],
//   createdAt = new Date(),
//   updatedAt = new Date()
// }) {
//   const projectsList = this.projects
//   const newProjectsList = insert(
//     projectsList,
//     { id, name, symbols, createdAt, updatedAt },
//     0
//   )
//   this.saveProjects(newProjectsList)
// },
// updateProjectById (id, data) {
//   const projectsList = this.projects
//   const projectIndex = projectsList.findIndex(p => p.id === id)
//   if (projectIndex !== -1) {
//     const project = Object.assign({},
//       projectsList[projectIndex],
//       data
//     )
//     projectsList.splice(projectIndex, 1, project)
//     this.saveProjects(projectsList)
//   }
// },
// deleteProject (id) {
//   const projectsList = this.projects
//   const projectIndex = projectsList.findIndex(p => p.id === id)
//   if (projectIndex !== -1) {
//     projectsList.splice(projectIndex, 1)
//     this.saveProjects(projectsList)
//   }
// }

// }
