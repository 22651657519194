export default async function removeSymbol ({ commit, dispatch, getters }, symbolId) {
  // check if tab is open with symbol and close it first
  const { tabId, editorId } = getters.resolveSymbolPath(symbolId)

  if (tabId) {
    await dispatch('closeTab', tabId)

    // remove editor
    commit('DELETE', { where: 'editors', id: editorId })
  }

  commit('DELETE', { where: 'symbols', id: symbolId })
}
