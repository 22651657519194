import Modal from './Modal'
import ModalContainer from './ModalContainer'
import UniversalModal from './UniversalModal'

const Plugin = {
  install (Vue) {
    // register components
    Vue.component('Modal', Modal)
    Vue.component('ModalContainer', ModalContainer)
    Vue.component('UniversalModal', UniversalModal)

    Vue.prototype.$modal = new Vue({
      data: {
        isVisible: false,
        component: null,
        props: {}
      },

      methods: {
        show ({ component, props } = {}) {
          this.props = props || {}
          this.component = component || 'UniversalModal'
          this.isVisible = true
        },

        close () {
          this.isVisible = false
          this.component = null
          this.props = {}
        }

      }
    })
  }
}

export default Plugin
