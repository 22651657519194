import without from 'lodash/without'
import last from 'lodash/last'

export default async function closeTab ({ state, commit, getters, dispatch }, tabId) {
  /*
    Remove editor and tab.
    If no tabs left in panel, close panel.
    If no panels left in panel group, remove panel group.
    Set new active panel.
    Set next active tab.
  */
  const oldTab = getters.itemById('tabs', tabId)

  if (!oldTab.canClose) return

  // don't delete editor, so text/node-selection is cached
  // remove editor when removing symbol

  // remove tab
  commit('DELETE', { where: 'tabs', id: oldTab.id })

  // update panel-tab relationship
  const panel = getters.findItemsByKeyValueContains('panels', 'tabIds', tabId)[0]
  const panelId = panel.id
  const panelGroup = getters.findItemsByKeyValueContains('panelGroups', 'panelIds', panelId)[0]
  const tabIds = without(panel.tabIds, tabId)
  const deletePanel = !tabIds.length
  const deletePanelGroup = deletePanel && panelGroup.panelIds.length === 1

  // if panel has no tabs left, delete and set new active panel
  if (deletePanel) {
    commit('DELETE', { where: 'panels', id: panelId })

    if (deletePanelGroup) {
      await dispatch('removePanelGroup', panelGroup.id)
    } else {
      commit('UPDATE', {
        where: 'panelGroups',
        data: {
          id: panelGroup.id,
          panelIds: without(panelGroup.panelIds, panelId)
        }
      })
    }
  } else {
    // update panel
    // set next active tab
    // try index position of deleted tab
    // if it doesn't exist, take last tab in panel
    const indexOfOldTab = panel.tabIds.indexOf(tabId)
    const tabIdAtOldIndex = tabIds[indexOfOldTab]
    const activeTabId = typeof tabIdAtOldIndex !== 'undefined'
      ? tabIdAtOldIndex
      : tabIds[tabIds.length - 1]

    commit('UPDATE', {
      where: 'panels',
      data: { id: panelId, activeTabId, tabIds }
    })
  }

  const nextActivePanelId = (() => {
    // TODO: when closing tab, focus panel the tab was opened from? maybe decide if it was closed by ESC or mouse?
    // get last active panel id; exclude panel the tab was in
    const lastActivePanelId = getters.getLastActivePanelId()
    if (lastActivePanelId) return lastActivePanelId

    // get id from panel group
    const lastPanelGroupId = last(state.panelGroupsOrder)
    const panelGroup = getters.itemById('panelGroups', lastPanelGroupId)
    return panelGroup.panelIds[0]
  })()

  commit('SET_ACTIVE_PANEL_ID', nextActivePanelId)
}
