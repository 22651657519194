
<script>
import classNames from 'classnames'

export default {
  name: 'Button',
  functional: true,

  props: {
    label: {
      type: String,
      required: true
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    active: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    },
    theme: {
      type: String,
      default: 'outline'
    },
    size: {
      type: String,
      default: 'normal'
    },
    inline: {
      type: Boolean,
      default: false
    },
    stretch: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    counter: {
      type: Number,
      default: 0
    }
  },

  render: function (h, ctx) {
    const buttonClass = () => {
      const size = {
        large: 'text-lg ' + (ctx.props.showLabel ? 'px-4 py-2' : 'p-0'),
        normal: 'text-base ' + (ctx.props.showLabel ? 'px-3 py-2' : 'p-0'),
        small: 'text-xs ' + (ctx.props.showLabel ? 'py-1 px-2.5' : 'p-0')
      }
      const theme = {
        primary: `
          text-white border border-blue-600 bg-blue-600
          hover:text-blue-50 hover:border-blue-700 hover:bg-blue-700
          focus:ring focus:ring-blue-600 focus:ring-opacity-25
          active:text-white active:bg-blue-700 active:border-blue-700
          disabled:cursor-default disabled:text-gray-50 disabled:border-gray-400 disabled:bg-gray-400
        `,
        outline: `
          text-black border border-black
          hover:text-blue-700 hover:border-blue-700
          focus:text-blue-700 focus:ring focus:ring-blue-500 focus:ring-opacity-25
          active:border-blue-700
          disabled:cursor-default disabled:text-gray-500 disabled:border-gray-500
        `,
        workbench: classNames(
          (ctx.props.active ? 'text-blue-700' : 'text-gray-800'),
          `
          bg-transparent
          hover:text-blue-700
          active:text-blue-700
          disabled:cursor-default disabled:text-gray-500
          `
        )
      }

      return classNames(
        (ctx.props.inline ? 'inline-flex' : 'flex'),
        'cursor-pointer items-center content-center justify-center flex-grow-0 flex-shrink-0 font-sans overflow-hidden transition ease-out duration-200 whitespace-nowrap',
        'focus:outline-none',
        size[ctx.props.size],
        theme[ctx.props.theme],
        {
          'w-full': ctx.props.stretch
        }
      )
    }

    const iconSize = () => {
      switch (ctx.props.size) {
        case 'small':
          return 22
        case 'normal':
          return 24
        case 'large':
          return 28
        default:
          return 24
      }
    }

    const mergedData = () => {
      const result = ctx.data
      result.ref = 'button'

      const classNormalized = (() => {
        const data = ctx.data.class
        if (!data) return null
        if (Array.isArray(data)) return data
        if (typeof data === 'object') {
          return Object.keys(data)
            .filter(key => data[key] === true)
        }
      })()

      result.class = classNormalized
        ? classNormalized.join(' ') + ' ' + buttonClass()
        : buttonClass()

      if (typeof result.domProps === 'undefined') {
        result.domProps = {}
      }
      result.domProps['aria-label'] = ctx.props.label

      return result
    }

    const children = () => {
      const result = []

      if (ctx.props.icon) {
        result.push(
          h(
            'span',
            {
              class: classNames(
                'm-1 text-current pointer-events-none',
                {
                  'mr-0': ctx.props.showLabel,
                  'text-red-700': ctx.props.counter > 0
                }
              )
            },
            [
              h(
                'component',
                {
                  is: ctx.props.icon,
                  props: {
                    size: iconSize()
                  }
                }
              )
            ]
          )
        )
      }

      if (ctx.props.showLabel) {
        result.push(
          h('span', { class: 'block font-medium' }, ctx.props.label)
        )
      }

      return result
    }

    const button = h(
      'button',
      mergedData(),
      children()
    )

    return button
  }

}
</script>
