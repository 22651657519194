import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import project from './modules/project'
import entities from './modules/entities'
import workbench from './modules/workbench'

Vue.use(Vuex)

export default new Vuex.Store({
  mutations,
  modules: { project, entities, workbench },
  strict: process.env.NODE_ENV === 'development'
})
