import tippy, { roundArrow } from 'tippy.js'
import './tippy.scss'

const defaultOptions = {
  arrow: roundArrow,
  theme: 'dark',
  placement: 'top-start',
  duration: [300, 200],
  maxWidth: 'none'
}

export default {
  install (Vue, options) {
    // add an instance method
    Vue.prototype.$tippy = function (el, options) {
      return tippy(el, Object.assign({}, defaultOptions, options)
      )
    }

    // tippy directive
    Vue.directive('tippy', {
      bind (el, binding, vnode, oldVnode) {
        if (!binding.value) return
        tippy(el, Object.assign({}, defaultOptions, binding.value))
      },

      // inserted: function () {},

      update: function (el, binding, vnode, oldVnode) {
        // update tippy options
        if (el._tippy) el._tippy.setProps(binding.value)
      },

      // componentUpdated: function () {},

      unbind: function (el) {
        if (el._tippy) el._tippy.destroy()
      }

    })
  }
}
