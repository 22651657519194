<template lang="pug">
  transition(name='quick-panel')

    //- backdrop
    .z-30.fixed.inset-0.w-full.flex.justify-center.items-start.bg-black.bg-opacity-25(
      ref='backdrop'
      @keydown.esc='close'
      @mousedown='handleClickAway'
    )
      //- wrapper
      .flex.flex-col.items-center.mt-16.w-full.max-w-lg
        .quick-panel-container.w-full.p-1.text-white.bg-black.shadow-modal.transition-transform
          slot

</template>

<script>
export default {
  name: 'QuickPanel',

  methods: {
    close () {
      this.$emit('close')
    },

    handleClickAway (e) {
      if (e.target === this.$refs.backdrop) {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss">
.quick-panel-enter {
  opacity: 0;
}

.quick-panel-leave-active {
  opacity: 0;
}

.quick-panel-enter .quick-panel-container,
.quick-panel-leave-active .quick-panel-container {
  transform: scale(1.025);
}

</style>
