const getters = {

  // getPanelGroupIndex: (state, getters) => groupId => state.panelGroupsOrder.indexOf(groupId),

  getLastActivePanelId: (state, getters) => exclude =>
    [].concat(state.activePanelHistory)
      .reverse()
      .find(id => {
        // exclude ids array
        if (Array.isArray(exclude) && exclude.includes(id)) return false
        // only return existing panels
        if (!getters.itemById('panels', id)) return false
        return true
      })
}

export default getters
