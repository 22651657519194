import Vue from 'vue'
import 'whatwg-fetch' // fetch polyfill: https://blog.logrocket.com/axios-or-fetch-api/
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import VueMeta from 'vue-meta'

/*
  Init functions
*/

function registerVendorComponents (Vue) {
  // split component
  Vue.component('Splitpanes', require('splitpanes').Splitpanes)
  Vue.component('Pane', require('splitpanes').Pane)

  // material icon components (https://materialdesignicons.com)
  Vue.component('ArrowUpIcon', require('vue-material-design-icons/ArrowUp.vue').default)
  Vue.component('ArrowDownIcon', require('vue-material-design-icons/ArrowDown.vue').default)
  Vue.component('PoundIcon', require('vue-material-design-icons/Pound.vue').default)
  Vue.component('DotsHorizontalIcon', require('vue-material-design-icons/DotsHorizontal.vue').default)
  Vue.component('ViewSplitVerticalIcon', require('vue-material-design-icons/ViewSplitVertical.vue').default)
  Vue.component('CloseIcon', require('vue-material-design-icons/WindowClose.vue').default)
  Vue.component('PlusIcon', require('vue-material-design-icons/Plus.vue').default)
  Vue.component('AlertOctagramIcon', require('vue-material-design-icons/AlertOctagram.vue').default)
  Vue.component('ContentCopyIcon', require('vue-material-design-icons/ContentCopy.vue').default)
  Vue.component('WrapIcon', require('vue-material-design-icons/Wrap.vue').default)
  Vue.component('WrapDisabledIcon', require('vue-material-design-icons/WrapDisabled.vue').default)
  Vue.component('SwapVerticalIcon', require('vue-material-design-icons/SwapVertical.vue').default)
  Vue.component('ViewSplitHorizontalIcon', require('vue-material-design-icons/ViewSplitHorizontal.vue').default)
  Vue.component('FileDownloadOutlineIcon', require('vue-material-design-icons/FileDownloadOutline.vue').default)
  Vue.component('TrashCanOutlineIcon', require('vue-material-design-icons/TrashCanOutline.vue').default)
  Vue.component('CloseThickIcon', require('vue-material-design-icons/CloseThick.vue').default)
  Vue.component('HelpCircleOutlineIcon', require('vue-material-design-icons/HelpCircleOutline.vue').default)
  Vue.component('FolderOutlineIcon', require('vue-material-design-icons/FolderOutline.vue').default)
  Vue.component('FolderOpenOutlineIcon', require('vue-material-design-icons/FolderOpenOutline.vue').default)
  Vue.component('ArrowExpandIcon', require('vue-material-design-icons/ArrowExpand.vue').default)
  Vue.component('SchoolOutlineIcon', require('vue-material-design-icons/SchoolOutline').default)
  Vue.component('LinkVariantIcon', require('vue-material-design-icons/LinkVariant').default)
  Vue.component('ContentSaveOutlineIcon', require('vue-material-design-icons/ContentSaveOutline').default)
  Vue.component('ContentSaveAlertOutlineIcon', require('vue-material-design-icons/ContentSaveAlertOutline').default)
  Vue.component('InformationVariantIcon', require('vue-material-design-icons/InformationVariant').default)
  Vue.component('ReloadIcon', require('vue-material-design-icons/Reload').default)
  Vue.component('CogOutlineIcon', require('vue-material-design-icons/CogOutline').default)
  Vue.component('PinOutlineIcon', require('vue-material-design-icons/PinOutline').default)
  Vue.component('BookmarkPlusOutlineIcon', require('vue-material-design-icons/BookmarkPlusOutline').default)
  Vue.component('CloudUploadOutlineIcon', require('vue-material-design-icons/CloudUploadOutline').default)
}

function registerVendorPlugins (Vue) {
  Vue.use(VueMeta)
}

function registerAppPlugins (Vue) {
  // app plugins
  Vue.use(require('@/plugins/tippy').default)
  Vue.use(require('@/plugins/toast').default)
  Vue.use(require('@/plugins/color').default)
  Vue.use(require('@/plugins/day').default)
  Vue.use(require('@/plugins/modal').default)
  Vue.use(require('@/plugins/quick-panel').default)
  Vue.use(require('@/plugins/talkative').default)
}

function registerAppComponents (Vue) {
  Vue.component('Scroll', require('@/components/panel/Scroll.vue').default)
  Vue.component('ContextMenu', require('@/components/ContextMenu.vue').default)
  Vue.component('PanelOutput', require('@/components/output/PanelOutput.vue').default)
  Vue.component('Button', require('@/components/form/Button.vue').default)
  Vue.component('TextInput', require('@/components/form/TextInput.vue').default)
  Vue.component('QuickPanelSymbolPicker', require('@/components/quick-panel/QuickPanelSymbolPicker.vue').default)
  Vue.component('PanelMenu', require('@/components/panel/PanelMenu.vue').default)
}

/*
  Init app
*/

registerVendorComponents(Vue)
registerVendorPlugins(Vue)
registerAppPlugins(Vue)
registerAppComponents(Vue)

// Vue config
Vue.config.productionTip = false

// init app component
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
