const Plugin = {
  install (Vue, options) {
    // register components
    Vue.component('QuickPanel', require('./QuickPanel.vue').default)
    Vue.component('QuickPanelContainer', require('./QuickPanelContainer.vue').default)

    Vue.prototype.$quickPanel = new Vue({
      data: {
        isVisible: false,
        component: null,
        props: {}
      },

      methods: {
        show ({ component, props } = {}) {
          if (!component) throw new Error('No quick panel component provided')
          this.props = props || {}
          this.component = component
          this.isVisible = true
        },

        close () {
          this.isVisible = false
          this.component = null
          this.props = {}
        }

      }
    })
  }
}

export default Plugin
