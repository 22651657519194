import mapValues from 'lodash/mapValues'
import last from 'lodash/last'

export default class History {
  /**
   * Each time a rule is selected, its index is stored in the history.
   */
  constructor (symbols) {
    this.entries = mapValues(symbols, s => new SymbolHistory(s.content.length))
  }

  find (id) {
    return this.entries[id]
  }
}

export class SymbolHistory {
  constructor (rulesCount) {
    if (typeof rulesCount !== 'number') {
      throw new Error('Wront arguments in SymbolHistory constructor')
    }

    this.steps = []
    this.rulesCount = rulesCount
  }

  add (index) {
    // don't add same index twice at the end of steps
    const lastIndex = last(this.steps)

    if (lastIndex !== index) {
      this.steps.push(index)
    }
  }

  undo () {
    this.steps.pop()
  }

  /**
   * Return indexes that were used recently
   */
  get recentSteps () {
    let result
    const steps = this.steps
    const stepsCount = steps.length
    const rulesCount = this.rulesCount

    if (stepsCount === 0) {
      result = []
    } else if (rulesCount > stepsCount) {
      result = steps
    } else {
      // Decide how many recent steps to return
      // When there are more than 5 rules:
      // Calculate how much X% of rulesCount is
      // Cut off that much at the end of steps array
      let sliceLen
      if (rulesCount <= 3) {
        sliceLen = 1 // return last step
      } else if (rulesCount <= 5) {
        sliceLen = 3
      } else {
        sliceLen = Math.floor((80 * rulesCount) / 100)
      }

      result = steps.slice(-sliceLen)
    }

    return new Set(result)
  }
}

// const symbolHistory = new SymbolHistory(6)
// symbolHistory.steps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17]
// console.warn(symbolHistory.steps)
// console.warn(symbolHistory.recentSteps)
