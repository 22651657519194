
const mutations = {

  UPDATE_PROJECT (state, project) {
    const keys = Object.keys(project)

    for (let i = 0, l = keys.length; i < l; i++) {
      const key = keys[i]
      const value = project[key]

      if (typeof state[key] === 'undefined') {
        console.warn(`Property ${key} is not a project property`)
        continue
      }

      state[key] = value
    }
  }

}

export default mutations
