import without from 'lodash/without'
import last from 'lodash/last'
import xor from 'lodash/xor'

export default async function updatePanelTabs (
  { commit, getters, dispatch },
  { panel, tabs }
) {
  // delete panel if tabs is empty
  if (!tabs.length) {
    commit('DELETE', { where: 'panels', id: panel.id })
    const panelGroup = getters.findItemsByKeyValueContains('panelGroups', 'panelIds', panel.id)[0]
    if (panelGroup.panelIds.length === 1) {
      // delete panel group if last tab just deleted
      await dispatch('removePanelGroup', panelGroup.id)
    } else {
      // update panel group
      commit('UPDATE', {
        where: 'panelGroups',
        data: {
          id: panelGroup.id,
          panelIds: without(panelGroup.panelIds, panel.id)
        }
      })
    }
  } else {
    // update tabIds
    // optionally change activeTabId
    const newTabIds = tabs.map(t => t.id)
    const oldTabIds = panel.tabIds
    const data = {
      id: panel.id,
      tabIds: newTabIds
    }

    if (newTabIds.length < oldTabIds.length) {
      // take last tabId
      data.activeTabId = last(newTabIds)
    } else {
      const diff = xor(oldTabIds, newTabIds)
      if (diff.length) {
        // take new tabId
        data.activeTabId = diff[0]
      }
    }
    commit('UPDATE', { where: 'panels', data })
  }
}
