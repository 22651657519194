import Vue from 'vue'
import { PanelGroup, Panel, Tab, Editor, Symbol } from './models'

const Model = {
  panelGroups: PanelGroup,
  panels: Panel,
  tabs: Tab,
  editors: Editor,
  symbols: Symbol
}

// const Model = (key) => keyModelMap[key]

export default {

  INSERT (state, { where, data, freeze = false }) {
    if (Array.isArray(data)) {
      for (let i = 0, l = data.length; i < l; i++) {
        const item = new Model[where](data[i])
        if (freeze) Object.freeze(item)
        Vue.set(state[where], item.id, item)
      }
    } else {
      const item = new Model[where](data)
      if (freeze) Object.freeze(item)
      Vue.set(state[where], data.id, item)
    }
  },

  UPDATE (state, { where, data, freeze = false }) {
    if (Array.isArray(data)) {
      for (let i = 0, l = data.length; i < l; i++) {
        const item = data[i]
        const id = item.id
        const merged = { ...state[where][id], ...item }
        if (freeze) Object.freeze(merged)
        Vue.set(state[where], id, merged)
      }
    } else {
      const id = data.id
      const merged = { ...state[where][id], ...data }
      if (freeze) Object.freeze(merged)
      Vue.set(state[where], id, merged)
    }
  },

  DELETE (state, { where, id }) {
    Vue.delete(state[where], id)
  }
}
