export const initialState = () => ({
  // these props are mutated by INSERT, UPDATE, DELETE
  panelGroups: {},
  panels: {},
  tabs: {},
  editors: {},
  symbols: {}
})

export default initialState
