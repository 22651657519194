export const initialState = () => ({
  lang: 'en',
  isDragging: false,
  activePanelId: null,
  activePanelHistory: [],
  panelGroupsOrder: [],
  hasUnsavedChanges: false
})

export default initialState
