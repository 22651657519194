<template lang="pug">
  Modal(
    :close-button='false'
    :maxWidth='maxWidth'
    @close='$modal.close'
  )
    template(slot='header') {{ title }}
    div(
      slot='body'
      v-html='content'
    )
    template(
      slot='footer'
    )
      Button(
        ref='button'
        v-for='(button, index) in buttons'
        :key='index + "-" + button.label'
        :class='{ "mr-2.5": index !== buttons.length - 1 }'
        :label='button.label'
        :theme='button.type ? button.type : "outline"'
        @click='button.onClick'
      )
</template>

<script>
export default {
  name: 'UniversalModal',

  props: {
    title: {
      type: String,
      default: ''
    },

    content: {
      type: String,
      default: ''
    },

    maxWidth: {
      type: String,
      default: '80vw'
    },

    buttons: {
      type: Array,
      default: () => []
    }
  },

  mounted () {
    // focus last button
    if (this.$refs.button) {
      if (Array.isArray(this.$refs.button)) {
        this.$refs.button[0].focus()
      } else {
        this.$refs.button.focus()
      }
    }
  }
}
</script>
