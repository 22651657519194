import { createId, insert } from '@/util'

export default async function openSymbolInTab (
  { commit, dispatch, state, rootState, getters },
  { symbolId, targetPanelId }
) {
  /**
   * If symbol already opened, get panelId to set activePanelId and tabId to set activeTabId.
   * If not: create panel or append to panel. Create new Tab with editor and symbol relationship.
   */
  let { panelId, tabId, editorId } = getters.resolveSymbolPath(symbolId)
  const activePanelId = state.activePanelId
  const activePanelGroup = getters.findItemsByKeyValueContains('panelGroups', 'panelIds', activePanelId)[0]
  let tabIds

  if (panelId) {
    // symbol already opened in tab
    const panel = getters.itemById('panels', panelId)
    tabIds = panel.tabIds
  } else {
    // symbol not open
    // create new panel or append new tab to panel with editor
    tabId = createId('tab')

    if (targetPanelId) {
      panelId = targetPanelId
      tabIds = insert(getters.itemById('panels', panelId).tabIds, tabId)
    } else {
      if (activePanelGroup.panelIds.length <= 1) {
        // create panel if only one exists
        panelId = createId('panel')
        tabIds = [tabId]
      } else {
        // open in existing panel
        // add tabId to tabIds
        // update tabIds in existing panel
        // try to open inside different panel from active panel, so current editor is not hiding
        panelId = activePanelGroup.panelIds.find(id => id !== activePanelId)
        const oldTabIds = getters.itemById('panels', panelId).tabIds
        tabIds = insert(oldTabIds, tabId)
      }
    }

    // check if editor with symbolId exists already; create new one, if not
    if (!editorId) {
      editorId = createId('editor')
      // create editor
      commit('INSERT', {
        where: 'editors',
        data: { id: editorId, symbolId }
      })
    }

    // create tab
    commit('INSERT', {
      where: 'tabs',
      data: {
        id: tabId,
        label: getters.itemById('symbols', symbolId).name,
        editorId: editorId
      }
    })
  }

  // insert or update panel
  const insertPanel = typeof rootState.entities.panels[panelId] === 'undefined'
  commit(insertPanel ? 'INSERT' : 'UPDATE', {
    where: 'panels',
    data: {
      id: panelId,
      activeTabId: tabId,
      tabIds
    }
  })

  // add panelId to panel group if new panel created
  if (insertPanel) {
    commit('UPDATE', {
      where: 'panelGroups',
      data: {
        id: activePanelGroup.id,
        panelIds: insert(activePanelGroup.panelIds, panelId)
      }
    })
  }

  // set active panel
  commit('SET_ACTIVE_PANEL_ID', panelId)
}
