import { initialState as entitiesState } from './modules/entities/state'
import { initialState as workbenchState } from './modules/workbench/state'

const mutations = {
  RESET_STATE (state) {
    const initialState = {
      // project is not reset
      entities: entitiesState(),
      workbench: workbenchState()
    }

    Object.keys(initialState).forEach(key => {
      Object.assign(state[key], initialState[key])
    })
    console.debug('State reset')
  }
}

export default mutations
