import { Inflectors } from 'en-inflectors'

/*
  Credits: Kate Compton (galaxykate)
  Source: https://github.com/galaxykate/tracery/blob/tracery2/js/tracery/mods-eng-basic.js
*/

function isVowel (c) {
  var c2 = c.toLowerCase()
  return (c2 === 'a') || (c2 === 'e') || (c2 === 'i') || (c2 === 'o') || (c2 === 'u')
};

const enModifiers = {
  article: (text) => {
    if (text.length > 0) {
      if (text.charAt(0).toLowerCase() === 'u') {
        if (text.length > 2) {
          if (text.charAt(2).toLowerCase() === 'i') { return 'a ' + text }
        }
      }

      if (isVowel(text.charAt(0))) {
        return 'an ' + text
      }
    }

    return 'a ' + text
  },

  plural: (text) => {
    return new Inflectors(text).toPlural()
  },

  past: (text) => {
    return new Inflectors(text).toPast()
  },

  capitalize: (text) => {
    return text.charAt(0).toUpperCase() + text.substring(1)
  },

  capitalizeAll: (text) => {
    return text.toUpperCase()
  },

  lower: (text) => {
    return text.toLowerCase()
  }
}

export default enModifiers
