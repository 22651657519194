import { createId, insert } from '@/util'

export default async function addPanel (
  { state, commit, getters },
  { panelIdFrom, dir = 1 } = {}
) {
  const newPanelId = createId('panel')
  const panelGroupFrom = getters.resolvePanelGroup(panelIdFrom)
  const oldPanelIds = panelGroupFrom.panelIds
  const panelIdFromPosition = oldPanelIds.indexOf(panelIdFrom)
  let newPanelPosition

  if (dir === 1) {
    newPanelPosition = panelIdFromPosition + 1
  } else {
    newPanelPosition = panelIdFromPosition > 0 ? panelIdFromPosition - 1 : panelIdFromPosition
  }

  const newPanelIds = insert(oldPanelIds, newPanelId, newPanelPosition)

  commit('INSERT', {
    where: 'panels',
    data: {
      id: newPanelId
    }
  })

  commit('UPDATE', {
    where: 'panelGroups',
    data: {
      id: panelGroupFrom.id,
      panelIds: newPanelIds
    }
  })
}
