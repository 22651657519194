import randomcolor from 'randomcolor'
import isPlainObject from 'lodash/isPlainObject'
import { createId } from '@/util'
import blankStory from '@/store/modules/workbench/actions/blank-story.json'

export const emptyDoc = blankStory[0].content

// credits: https://github.com/vuex-orm/vuex-orm/tree/master/src/attributes/types
class Model {
  constructor (record) {
    const fields = this.constructor.fields()

    for (const key in fields) {
      const field = fields[key]
      this[key] = field(record[key])
    }
  }

  static uid (fallback, isNullable) {
    return (value) => {
      const type = typeof value

      if (type === 'undefined') {
        if (typeof fallback !== 'undefined') {
          return fallback
        } else {
          return createId()
        }
      }

      if (type === 'string' || type === 'number') {
        return value
      }

      if (value === null && isNullable === true) {
        return value
      }

      console.warn(`Expected uid, got "${type}" (Value: "${value}")`)

      return value + ''
    }
  }

  static string (fallback = '', isNullable) {
    return (value) => {
      const type = typeof value

      if (type === 'undefined') {
        return fallback
      }

      if (type === 'string') {
        return value
      }

      if (value === null && isNullable === true) {
        return value
      }

      console.warn(`Expected string, got "${type}" (Value: "${value}")`)

      return value + ''
    }
  }

  static number (fallback = 0, isNullable) {
    return (value) => {
      const type = typeof value

      if (type === 'undefined') {
        return fallback
      }

      if (type === 'number') {
        return value
      }

      if (value === null && isNullable === true) {
        return value
      }

      console.warn(`Expected number, got "${type}" (Value: "${value}")`)

      if (type === 'string') {
        return parseFloat(value)
      }

      if (type === 'boolean') {
        return value ? 1 : 0
      }

      return fallback
    }
  }

  static boolean (fallback = false, isNullable) {
    return (value) => {
      const type = typeof value

      if (type === 'undefined') {
        return fallback
      }

      if (type === 'boolean') {
        return value
      }

      if (value === null && isNullable === true) {
        return value
      }

      console.warn(`Expected boolean, got "${type}" (Value: "${value}")`)

      if (type === 'string') {
        if (value.length === 0) {
          return false
        }

        const int = parseInt(value, 0)

        return isNaN(int) ? true : !!int
      }

      if (type === 'number') {
        return !!value
      }

      return fallback
    }
  }

  static array (fallback = [], isNullable) {
    return (value) => {
      if (typeof value === 'undefined') {
        return fallback
      }

      if (Array.isArray(value)) {
        return value
      }

      if (value === null && isNullable === true) {
        return value
      }

      console.warn(`Expected array, got "${typeof value}" (Value: "${value}")`)

      return [value]
    }
  }

  static object (fallback = {}, isNullable) {
    return (value) => {
      if (typeof value === 'undefined') {
        return fallback
      }

      if (isPlainObject(value)) {
        return value
      }

      if (value === null && isNullable === true) {
        return value
      }

      console.warn(`Expected object, got "${typeof value}" (Value: "${value}")`)

      return fallback
    }
  }

  static date (fallback = {}, isNullable) {
    return (value) => {
      if (typeof value === 'undefined') {
        return fallback
      }

      if (value instanceof Date) {
        return value
      }

      if (value === null && isNullable === true) {
        return value
      }

      console.warn(`Expected date, got "${typeof value}" (Value: "${value}")`)

      return fallback
    }
  }

  static fields () {
    return {}
  }
}

export class PanelGroup extends Model {
  static fields () {
    return {
      id: this.uid(createId('panel-group')),
      panelIds: this.array([])
    }
  }
}

export class Panel extends Model {
  static fields () {
    return {
      id: this.uid(createId('panel')),
      activeTabId: this.string(null, true),
      tabIds: this.array([])
    }
  }
}

export class Tab extends Model {
  static fields () {
    return {
      id: this.uid(createId('tab')),
      label: this.string('Untitled'),
      icon: this.string(null, true),
      canClose: this.boolean(true),
      componentName: this.string(null, true),
      editorId: this.string(null, true)
    }
  }
}

export class Editor extends Model {
  static fields () {
    return {
      id: this.uid(createId('editor')),
      symbolId: this.string(null, true),
      wordWrap: this.boolean(false),
      selection: this.object({ isNodeSel: false, from: null, to: null }),
      scrollPos: this.object({ top: 0, left: 0 })
    }
  }
}

export class Symbol extends Model {
  static fields () {
    return {
      id: this.uid(createId('symbol')),
      name: this.string('Untitled'),
      color: this.string(randomcolor()),
      method: this.string('rand'),
      repeat: this.boolean(true),
      content: this.array(emptyDoc)
    }
  }
}
