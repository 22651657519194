import without from 'lodash/without'

export default async function removePanel (
  { state, commit, getters },
  panelId
) {
  const panelGroup = getters.resolvePanelGroup(panelId)
  commit('DELETE', { where: 'panels', id: panelId })
  commit('UPDATE', {
    where: 'panelGroups',
    data: {
      id: panelGroup.id,
      panelIds: without(panelGroup.panelIds, panelId)
    }
  })
}
