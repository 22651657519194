const actions = {
  createWorkspace: require('./createWorkspace').default,
  openSymbolInTab: require('./openSymbolInTab').default,
  removeSymbol: require('./removeSymbol').default,
  closeTab: require('./closeTab').default,
  openComponentInTab: require('./openComponentInTab').default,
  addPanelGroup: require('./addPanelGroup.js').default,
  addPanel: require('./addPanel.js').default,
  removePanel: require('./removePanel.js').default,
  removePanelGroup: require('./removePanelGroup.js').default,
  updatePanelTabs: require('./updatePanelTabs.js').default,
  focusLastActivePanel: require('./focusLastActivePanel.js').default
}

export default actions
