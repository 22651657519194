import without from 'lodash/without'

export default async function removePanelGroup ({ state, commit, getters }, panelGroupId) {
  const panelGroup = getters.itemById('panelGroups', panelGroupId)

  // check if panel group has panel(s) with tabs
  const hasTabsOpen = panelGroup.panelIds.some(id => {
    const panel = getters.itemById('panels', id)
    if (!panel) return false // if panel doesnt exist, return false
    if (panel.tabIds.length > 0) return true
  })

  if (hasTabsOpen) {
    throw new Error("Can't close non-empty panel group")
  }

  commit('SET_PANEL_GROUPS_ORDER', without(state.panelGroupsOrder, panelGroupId))

  commit('DELETE', {
    where: 'panelGroups',
    id: panelGroupId
  })
}
