<template lang="pug">
  transition(name='modal')
    .z-50.fixed.inset-0.w-full.h-full.flex.justify-center.items-center.bg-black.bg-opacity-40(
      ref='modal-mask'
      role='dialog'
      tabindex='-1'
      aria-labelledby='modalTitle'
      aria-describedby='modalDescription'
      @keydown.esc='escKey ? close() : null'
      @mousedown='handleClickAway'
    )
      //- wrapper
      .flex.items-center.flex-col(:style='inlineStyle')
        //- container
        .modal-container.w-full.bg-white.shadow-modal.transition-transform
          header#modalTitle.flex.p-4.select-none.justify-between.text-white.bg-blue-700
            h1.font-medium
              slot(name='header')
                | Untitled
            Button(
              v-if='closeButton'
              @click='close'
              icon='CloseThickIcon'
              label='Close'
              :show-label='false'
            )

          section#modalDescription.relative.p-4
            slot(name='body')

          footer.flex.p-4.select-none.border-t.border-gray-100.justify-end
            slot(name='footer')
              Button(
                @click='close'
                label='Cancel'
              )

</template>

<script>
export default {
  name: 'Modal',

  props: {
    closeButton: {
      type: Boolean,
      default: true
    },

    escKey: {
      type: Boolean,
      default: true
    },

    clickAway: {
      type: Boolean,
      default: true
    },

    minWidth: {
      type: String,
      required: false
    },

    maxWidth: {
      type: String,
      default: '90%'
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    handleClickAway (e) {
      if (this.clickAway && e.target === this.$refs['modal-mask']) {
        this.close()
      }
    }
  },

  computed: {
    inlineStyle () {
      const result = {
        maxWidth: this.maxWidth
      }

      if (typeof this.minWidth !== 'undefined') {
        result.minWidth = this.minWidth
      }

      return result
    }
  }
}
</script>

<style lang="scss">
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
