import Vue from 'vue'
import Router from 'vue-router'
import { hasPointer } from '@/util'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ selector: to.hash, behavior: 'smooth' })
        }, 500)
      })
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/home',
      name: 'home',
      component: () => import(/* webpackChunkName: "info-content" */ '@/views/Home.vue')
    },
    {
      path: '/tutorial',
      name: 'tutorial',
      meta: { showHeader: true, redirectMobile: true },
      component: () => import('@/views/Tutorial.vue')
    },
    {
      path: '/resources',
      name: 'resources',
      meta: { showHeader: true },
      component: () => import(/* webpackChunkName: "info-content" */ '@/views/Resources.vue')
    },
    {
      path: '/about',
      name: 'about',
      meta: { showHeader: true },
      component: () => import(/* webpackChunkName: "info-content" */ '@/views/About.vue')
    },
    {
      path: '/:projectId([a-z0-9\\-_]{10,})?',
      name: 'workbench',
      meta: { showHeader: false, redirectMobile: true },
      component: () => import('@/views/Workbench.vue'),
      beforeEnter (to, from, next) {
        // redirect to home if this is the first time
        // check if localStorage value exist
        if (!localStorage.getItem('isReturningVisitor')) {
          localStorage.setItem('isReturningVisitor', '1')

          if (from.path === '/') {
            return next('home')
          }
        }

        next()
      }
    },
    // TODO: show 404 page
    // Everything else (404)
    {
      path: '*',
      redirect: '/'
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (!hasPointer && to.meta.redirectMobile === true) {
    return next({ name: 'home', replace: true })
  }
  next()
})

export default router
