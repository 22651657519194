<script>
import classNames from 'classnames'

export default {
  name: 'TextInput',

  props: {

    id: {
      type: String,
      required: false
    },

    type: {
      type: String,
      default: 'text'
    },

    theme: {
      type: String,
      default: 'regular'
    },

    label: {
      type: String,
      required: false
    },

    value: {
      type: String,
      default: ''
    },

    autocomplete: {
      type: Object,
      default: () => null,
      validator: (val) => {
        if (!Array.isArray(val.items)) return false
        if (typeof val.showOnStart !== 'undefined' && typeof val.showOnStart !== 'boolean') return false
        if (typeof val.resultsStartAt !== 'undefined' && typeof val.resultsStartAt !== 'number') return false
        return true
      }
    },

    size: {
      type: String,
      default: 'normal'
    },

    transparent: {
      type: Boolean,
      default: false
    },

    min: {
      type: String,
      required: false
    },

    max: {
      type: String,
      required: false
    },

    step: {
      type: String,
      required: false
    },

    placeholder: {
      type: String,
      required: false
    },

    maxlength: {
      type: String,
      required: false
    },

    required: {
      type: Boolean,
      required: false
    },

    readonly: {
      type: Boolean,
      required: false
    },

    hint: {
      type: String,
      required: false
    },

    pattern: {
      type: String,
      required: false
    },

    requirements: {
      type: String,
      required: false
    }
    // tooltip: {
    //   type: Object,
    //   default: () => {}
    // }
  },

  render: function (h, context) {
    const children = () => {
      const result = []

      const labelElement = () => {
        return h(
          'label',
          { class: 'font-medium text-sm' },
          this.label
        )
      }

      const inputDomAttrs = () => {
        const result = {
          type: this.type,
          autocomplete: 'off',
          autocorrect: 'off',
          autocapitalize: 'off',
          spellcheck: 'false'
        }
        if (typeof this.id !== 'undefined') result.id = this.id
        if (typeof this.min !== 'undefined') result.min = this.min
        if (typeof this.max !== 'undefined') result.max = this.max
        if (typeof this.step !== 'undefined') result.step = this.step
        if (typeof this.required !== 'undefined') result.required = this.required
        if (typeof this.pattern !== 'undefined') result.pattern = this.pattern
        if (typeof this.readonly !== 'undefined') result.readonly = this.readonly
        if (typeof this.placeholder !== 'undefined') result.placeholder = this.placeholder
        if (typeof this.maxlength !== 'undefined') result.maxlength = this.maxlength

        return result
      }

      const inputClasses = () => {
        const type = {
          text: 'px-2',
          number: 'pl-2',
          search: 'pl-2'
        }
        const theme = {
          regular: classNames(
            'border text-black',
            this.transparent
              ? 'bg-white bg-opacity-25 focus:bg-white placeholder-gray-900'
              : 'bg-white placeholder-gray-400',
            this.touched && !this.isValid
              ? 'border-red-500 focus:ring focus:ring-red-500 focus:ring-opacity-25'
              : 'border-gray-700 focus:border-blue-600 focus:ring focus:ring-blue-600 focus:ring-opacity-25'
          ),
          quickpanel: classNames(
            'border-2 border-blue-500 border-opacity-75 text-white placeholder-gray-700',
            { 'bg-black': !this.transparent }
          )
        }
        const size = {
          normal: 'h-10 text-base',
          small: 'h-6 text-sm'
        }

        return classNames(
          'w-full transition focus:outline-none',
          type[this.type],
          theme[this.theme],
          size[this.size]
        )
      }

      const inputElement = () => {
        const eventHandlers = {
          input: this.onInput
        }
        if (this.autocomplete) {
          eventHandlers.keydown = this.onKeyDown
        }

        return h(
          'input',
          {
            ref: 'input',
            class: inputClasses(),
            attrs: inputDomAttrs(),
            domProps: {
              value: this.value
            },
            on: eventHandlers
          }
        )
      }

      const suggestions = () => {
        const result = []
        const itemsLen = this.autocompleteItems.length
        const items = []
        if (!itemsLen) return []

        // create items
        for (let i = 0, l = itemsLen; i < l; i++) {
          const item = this.autocompleteItems[i]
          const classes = ['suggestion-item cursor-pointer select-none']
          const isActiveItem = this.navigatedIndex === i

          if (isActiveItem) {
            classes.push('suggestion-item--selected')
          }

          items.push(
            h(
              'li',
              {
                ref: isActiveItem ? 'activeSuggestion' : null,
                class: classes,
                domProps: {
                  innerHTML: item.label
                },
                on: {
                  click: () => this.selectSuggestion(item)
                }
              }
            )
          )
        }

        // create and insert suggestions container
        result.push(
          h(
            'div',
            { class: 'relative' },
            [
              h(
                'ul',
                { class: 'suggestions flex flex-col mt-1 list-none max-h-52 overflow-x-hidden overflow-y-auto' },
                items
              )
            ]
          )
        )

        return result
      }

      const hint = () => {
        return h(
          'div',
          { class: 'text-xs mt-1.5' },
          this.hint
        )
      }

      const requirements = () => {
        return h(
          'div',
          {
            class: classNames(
              'text-xs mt-1.5 text-red-500',
              (this.touched && !this.isValid ? 'visible' : 'invisible')
            )
          },
          this.requirements
        )
      }

      if (typeof this.label === 'string') {
        result.push(labelElement())
      }

      result.push(inputElement())

      if (
        this.autocomplete &&
      (
        this.autocomplete.showOnStart ||
        this.touched
      )
      ) {
        result.push(suggestions())
      }

      if (this.hint && this.isValid) {
        result.push(hint())
      }

      if (typeof this.requirements === 'string') {
        result.push(requirements())
      }

      return result
    }

    return h(
      'div',
      children()
    )
  },

  data () {
    return {
      isValid: true,
      touched: false,
      navigatedIndex: 0
    }
  },

  computed: {

    autocompleteItems () {
      return this.autocomplete ? this.autocomplete.items : []
    },

    autocompleteStartIndex () {
      return this.autocomplete &&
        typeof this.autocomplete.resultsStartAt === 'number'
        ? this.autocomplete.resultsStartAt
        : 0
    }

  },

  methods: {

    onInput () {
      if (!this.touched) this.touched = true
      const el = this.$refs.input
      const value = el.value

      this.isValid = el.checkValidity()
      this.$emit('input', value)
      this.$nextTick(() => {
        this.navigatedIndex = this.autocompleteStartIndex
      })
    },

    focus () {
      this.$refs.input.focus()
    },

    onKeyDown (e) {
      // pressing up arrow
      if (e.key === 'ArrowUp') {
        this.upHandler()
        this.scrollIntoView({ block: 'nearest' })
        e.preventDefault()
      } else if (e.key === 'ArrowDown') {
        this.downHandler()
        e.preventDefault()
        this.scrollIntoView({ block: 'nearest' })
      } else if (e.key === 'Enter') {
        this.enterHandler()
      }
    },

    upHandler () {
      const listLen = this.autocompleteItems.length
      if (this.navigatedIndex === 0) {
        this.navigatedIndex = listLen - 1
        return
      }
      this.navigatedIndex -= 1
    },

    downHandler () {
      const listLen = this.autocompleteItems.length
      if (this.navigatedIndex === listLen - 1) {
        this.navigatedIndex = this.autocompleteStartIndex
        return
      }
      this.navigatedIndex += 1
    },

    enterHandler () {
      this.selectSuggestion(this.autocompleteItems[this.navigatedIndex])
    },

    selectSuggestion (item) {
      this.$emit('selectItem', item)
    },

    scrollIntoView (alignToTop) {
      // this.$refs.activeSuggestion.scrollIntoView()
      this.$nextTick(() => {
        const el = document.querySelector('.suggestion-item--selected')
        if (el) el.scrollIntoView(alignToTop)
      })
    }
  }

}
</script>
