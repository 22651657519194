import { BrowserStorage, BackendStorage } from '@/storage'

export default async function saveProject ({ state, commit, getters }) {
  // set updatedAt
  commit('UPDATE_PROJECT', { updatedAt: new Date() })

  // create storage item
  const storageItem = {
    ...state,
    symbols: getters.allItems('symbols')
  }

  if (storageItem.id === process.env.VUE_APP_LOCAL_PROJECT_ID) {
    // save locally
    BrowserStorage.save(storageItem.id, storageItem)
  } else {
    // save live
    await BackendStorage.updateProject(storageItem.id, storageItem)
  }

  commit('SET_HAS_UNSAVED_CHANGES', false)
}
