import { BrowserStorage, BackendStorage } from '@/storage'

export default async function loadProject ({ commit, dispatch, getters }, projectId) {
  const localProjectId = process.env.VUE_APP_LOCAL_PROJECT_ID
  let storageItem

  if (
    projectId &&
    projectId !== localProjectId
  ) {
    // check id and return error if wrong
    if (!BackendStorage.isValidId(projectId)) {
      throw new Error('Invalid ID')
    }
    // load project from backend
    storageItem = await BackendStorage.getProject(projectId)
  } else {
    // load local project
    storageItem = BrowserStorage.get(localProjectId)
  }

  // get project and symbols from storage item
  let project, symbols
  if (storageItem) {
    if (Array.isArray(storageItem)) {
      // legacy: in old versions,
      // storageItem was an array of symbols
      project = null
      symbols = storageItem
    } else if (typeof storageItem.symbols !== 'undefined') {
      symbols = storageItem.symbols
      delete storageItem.symbols
      project = storageItem
    } else {
      throw new Error('Could not parse file 😡')
    }
  }

  return { project, symbols }
}
