export default function migrateSymbols (symbolsOld) {
  const firstRuleChunkType = symbolsOld[0].content.find(x => x.content.length > 0).content[0].type
  const isCurrentSchema = ['text', 'symbol', 'rulegroup'].includes(firstRuleChunkType)
  const isOldSchema = firstRuleChunkType === 'rule_variant'
  const removeEmptyTextNodes = (content) => content
    ? content.filter(n => {
    // remove falsy created empty text nodes by an earlier version of Plauder
      if (n.type === 'text' && !n.text.length) {
        return false
      }
      return true
    })
    : []

  if (isCurrentSchema) {
    return symbolsOld
  }

  // schema before 1.0
  if (isOldSchema) {
    // loop symbols
    const result = []

    for (let i = 0, l = symbolsOld.length; i < l; i++) {
      const symbol = symbolsOld[i]
      const rules = symbol.content
      const newSymbolContent = []

      // loop variants
      for (let j = 0, l2 = rules.length; j < l2; j++) {
        const rule = rules[j]
        const variants = rule.content
        const variantCount = variants.length

        // loop variants
        if (variantCount > 1) {
          const ruleGroup = {
            type: 'rulegroup',
            content: []
          }

          for (let x = 0; x < variantCount; x++) {
            const variant = variants[x]

            if (variant.type !== 'rule_variant') continue

            ruleGroup.content.push({
              type: 'rule',
              content: removeEmptyTextNodes(variant.content)
            })
          }

          newSymbolContent.push(ruleGroup)
        } else if (variantCount === 1) {
          newSymbolContent.push({
            type: 'rule',
            content: removeEmptyTextNodes(variants[0].content)
          })
        }
      }

      symbol.content = newSymbolContent
      result.push(symbol)
    }

    console.info('Symbols migrated')
    return result
  }
}
