import { symbolType, textType } from './'
import modifiers from './modifiers'

export default function flatten (nodesArr, lang) {
  const textArr = []

  for (let i = 0, l = nodesArr.length; i < l; i++) {
    const node = nodesArr[i]

    if (node.type === textType) {
      textArr.push(node.text)
    } else if (node.type === symbolType) {
      // TODO: skip empty strings (?)
      textArr.push(
        applyModifiers(
          flatten(node.nodes, lang),
          node.mods,
          lang
        )
      )
    }
  }

  return textArr.join('')
}

const applyModifiers = (text, mods, lang) => {
  if (!mods) return text // legacy support
  const keys = Object.keys(mods)
  let i = keys.length
  while (i--) text = modifiers[lang][keys[i]](text)
  return text
}
