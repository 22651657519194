
export default function selectRule (options) {
  // check if there's a saved rules selection and return if so
  const { symbolNodeAttrs, savedRules } = options
  const savedRuleKey = symbolNodeAttrs.save
  const symbolId = symbolNodeAttrs.id
  const candidates = createCandidates(options)
  if (!candidates.length) return null

  // TODO: methods: rand, freq, uniq
  const ruleCandidate = selectRuleRandomly(candidates)

  // save selection index, if there's a save-key
  if (savedRuleKey) {
    if (!savedRules[symbolId]) {
      savedRules[symbolId] = {}
    }
    savedRules[symbolId][savedRuleKey] = ruleCandidate.index
  }

  return ruleCandidate
}

const createCandidates = ({
  symbolContent,
  symbolNodeAttrs,
  symbolHistory,
  ignoredIndexes,
  savedRules
}) => {
  const blocks = symbolContent
  const variantIndex = symbolNodeAttrs.variant > 1
    ? symbolNodeAttrs.variant - 1
    : 0
  const savedRuleKey = symbolNodeAttrs.save
  const symbolId = symbolNodeAttrs.id
  const savedIndex = savedRules[symbolId]?.[savedRuleKey]

  // return early if there is a saved selection index of rule or rulegroup
  if (savedIndex >= 0) {
    // resolve rule, otherwise return empty rule when if the varian position does not match
    const rule = resolveRule(
      blocks[savedIndex], variantIndex
    ) || {
      type: 'rule',
      content: [{ type: 'text', text: '' }]
    }
    return [{
      index: savedIndex,
      rule
    }]
  }

  const result = []
  const blockCount = blocks.length
  const recentSteps = symbolHistory.recentSteps
  const hasOneBlock = blockCount === 1
  let index = blockCount
  while (index--) {
    if (
      hasOneBlock ||
      (
        !recentSteps.has(index) &&
        !ignoredIndexes.has(index)
      )
    ) {
      const rule = resolveRule(
        blocks[index], variantIndex
      )

      if (rule) {
        result.push({ index, rule })
      }
    }
  }

  return result
}

const resolveRule = (block, variantIndex) => {
  const rule = block.type === 'rulegroup'
    ? block.content[variantIndex]
    : block

  if (!rule) return null

  // if rule has no content, add empty content object
  // (prosemirror disallows empty text nodes, but we have to add it internally)
  if (!rule.content) {
    rule.content = [{ type: 'text', text: '' }]
  }

  return rule
}

const selectRuleRandomly = rules => {
  const randomIndex = rules.length * Math.random() | 0
  return rules[randomIndex]
}
