import createStory from './createStory'
import { downloadFromString, createOutputFilename } from '@/util'

export const noRulesCode = 'norules'
export const symbolType = 1
export const textType = 2

const Plugin = {
  install (Vue) {
    const vm = new Vue({
      data: {
        text: '',
        savedRules: {},
        messages: {
          errors: []
        }
      }
    })

    function createStoryInstance ({ symbolId, symbols, store }, cb) {
      const { startSymbolId, separator } = store.state.project
      const wantedSymbolId = symbolId || startSymbolId
      const wantedSymbols = symbols || store.state.entities.symbols

      const story = createStory(
        wantedSymbolId,
        wantedSymbols,
        { separator }
      )

      return cb(story)
    }

    Vue.prototype.$generate = function ({ symbolId, symbols } = {}) {
      return createStoryInstance({ symbolId, symbols, store: this.$store }, (story) => {
        story.generate()

        vm.text = story.text
        vm.messages = story.messages
        vm.savedRules = story.savedRules

        return {
          text: story.text,
          messages: story.messages,
          savedRules: story.savedRules
        }
      })
    }

    Vue.prototype.$generateAndDownload = function ({ symbolId, symbols, charCount = 1800 } = {}) {
      createStoryInstance({ symbolId, symbols, store: this.$store }, (story) => {
        story.generate({ minLength: charCount })

        console.debug('Errors', story.errors)
        console.debug(`Output length: ${story.text.length}/${charCount}`)

        const filename = createOutputFilename()
        downloadFromString(story.text, `result-${filename}.txt`)
      })
    }

    Object.defineProperties(Vue.prototype, {
      $generatedText: {
        get: function () {
          return vm.text
        }
      },
      $generateMessages: {
        get: function () {
          return vm.messages
        }
      },
      $savedRules: {
        get: function () {
          return vm.savedRules
        }
      }
    })
  }
}

export default Plugin
