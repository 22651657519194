import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

export default {
  install (Vue) {
    Vue.prototype.$toast = new Notyf({
      types: [
        {
          type: 'success',
          dismissible: true,
          duration: 4000,
          backgroundColor: '#212121'
        },
        {
          type: 'error',
          dismissible: true,
          icon: false,
          duration: 0
          // backgroundColor: '#1c0052',
        }
      ]
    })
  }
}
