import { noRulesCode, symbolType, textType } from './'
import selectRule from './selectRule'
import undo from './undo'

// recursive function
export default function buildState (
  story,
  symbolNodeAttrs,
  symbolNodeAttrsFrom,
  savedRules
) {
  const { symbols, history, errors } = story
  const symbolId = symbolNodeAttrs.id
  const symbol = symbols[symbolId]

  if (typeof symbol === 'undefined') {
    const msg = `Symbol <strong>#${symbolNodeAttrs.name}</strong> doesn't exist. Please remove it from <strong>#${symbolNodeAttrsFrom.name}</strong>.`
    errors.push(msg)
    return noRulesCode
  }

  const symbolContent = symbol.content
  const symbolContentLength = symbolContent.length
  const symbolHistory = history.find(symbolId)
  const ignoredIndexes = new Set()

  while (true) {
    // if all rules tried, break while loop
    if (ignoredIndexes.size === symbolContentLength) {
      return noRulesCode
    }

    const selectResult = selectRule({
      symbolContent,
      symbolNodeAttrs,
      symbolHistory,
      ignoredIndexes,
      savedRules
    })

    if (!selectResult) {
      return noRulesCode
    }

    const { index, rule } = selectResult

    // recursivly expand symbol nodes
    // discard rule, if any recursive buildState call returned "noRulesCode"
    let ruleIsOk = true
    const expandedNodes = []
    for (let i = 0, l = rule.content.length; i < l; i++) {
      const node = rule.content[i]

      if (node.type === 'text') {
        expandedNodes.push({
          type: textType, text: node.text
        })
      } else if (node.type === 'hard_break') {
        expandedNodes.push({
          type: textType, text: '\n'
        })
      } else if (node.type === 'symbol') {
        const recursiveCallResult = buildState(
          story,
          node.attrs,
          symbolNodeAttrs,
          savedRules
        )
        if (recursiveCallResult === noRulesCode) {
          ruleIsOk = false
          break
        }
        expandedNodes.push(...recursiveCallResult)
      }
    }

    if (ruleIsOk) {
      // add to history
      symbolHistory.add(index)
      return [{
        type: symbolType,
        id: symbolId,
        mods: symbolNodeAttrs.mods,
        nodes: expandedNodes
      }]
    } else {
      // recursivly undo history for expanded symbols
      if (expandedNodes.length) {
        undo(history, expandedNodes)
      }

      // remember rule to exlcude next time
      if (!ignoredIndexes.has(index)) {
        ignoredIndexes.add(index)
      }
    }
  }
}
