import { createId, insert } from '@/util'

export default async function addPanelGroup (
  { state, commit, getters },
  { panelIdFrom, dir = 1 } = {}
) {
  const oldPanelGroupsOrder = state.panelGroupsOrder
  const newPanelGroupId = createId('pg')
  const panelId = createId('panel')

  // append new panel group to order array
  let index
  const panelGroupFrom = getters.resolvePanelGroup(panelIdFrom)
  const triggerIndex = oldPanelGroupsOrder.indexOf(panelGroupFrom.id)
  if (dir === -1) {
    index = triggerIndex > 0 ? triggerIndex - 1 : 0
  } else if (dir === 1) {
    index = triggerIndex + 1
  }
  const newPanelGroupsOrder = insert(oldPanelGroupsOrder, newPanelGroupId, index)

  commit('INSERT', {
    where: 'panels',
    data: {
      id: panelId
    }
  })

  commit('INSERT', {
    where: 'panelGroups',
    data: {
      id: newPanelGroupId,
      panelIds: [panelId]
    }
  })

  commit('SET_PANEL_GROUPS_ORDER', newPanelGroupsOrder)
}
